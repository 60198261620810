

.sochi-radio-group {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    
    &__radio {

    }

    &__input {
        margin: 5px;
        width: 20px;
        height: 20px;
    }
}