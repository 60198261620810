@font-face {
    font-family: "AvenirNextCyr";
    src: url("../Fonts/AvenirNextCyr-Heavy.woff") format("woff"),
    }



.sochi-host {
    &__tableId {
        font-size:8px;
    }
    &__teamSum {
        font-family: "AvenirNextCyr";
        margin-top: 5px;
        padding:5px 5px;
        font-size:18px;
        border-radius:10%;
        color: #ffffff;
        background-color: #0066A4;
    }

    &__buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 8px;
       
    }

    &__button {
        font-family: "AvenirNextCyr";
        padding:5px 5px;
        border: none;
        height:30px;
        width:50px;
        border-radius:2px;
        font-size:14px;
        background-color: #4F585A;
        color: #ffffff;
        &_selected {
            border: 2px solid orangered;
        }

        &_active {
            border: 2px solid orangered;
            background-color: orangered;
        }
        
        color: #ffffff;
        border-radius: 10%;
    }

    &__subbuttons {
        padding-top: 30px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__letters {
        font-family: "AvenirNextCyr";
        display: flex;
        padding-top: 20px;
        align-items: center;
        font-size: 20px;
        gap: 20px;
        font-size: 100%;
        height: 60px;
    }

    &__subtext {
        font-family: "AvenirNextCyr";
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;
        font-size: 10px;
        //gap: 20px;
       // font-size: 100%;
       // height: 60px;
    }



    &__primary-button {
        font-family: "AvenirNextCyr";
        border-radius: 4px;
        font-size: medium;
        appearance: button;
        backface-visibility: hidden;
        background-color: #118ab2;
        border-width: 0;
        color: #fff;
        cursor: pointer;
        font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
        font-size: 100%;
        height: 44px;
        width: 50px;
        line-height: 1.15;
        margin: 5px 5px 5px 5px;
        outline: none;
        overflow: hidden;
        padding: 0 15 5 10;
        position: relative;
        text-align: center;
        touch-action: manipulation;
 
    }

    &__activate_table {
        font-family: "AvenirNextCyr";
        border-radius: 4px;
        padding: 2px 4px;
        font-size: medium;
        appearance: button;
        backface-visibility: hidden;
        background-color: #ef476f;
        border-radius: 6px;
        border-width: 0;
        box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        
        font-size: 100%;
        height: 84px;
        line-height: 1.15;
        margin: 12px 0 0;
        outline: none;
        overflow: hidden;
        padding: 0 25px;
        position: relative;
        text-align: center;
        text-transform: none;
        transform: translateZ(0);
        transition: all .2s,box-shadow .08s ease-in;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
       
    }


    &__input-text {
        font-family: "AvenirNextCyr";
        margin-right: 30px;
        margin-top: 0px;
        padding: 10px 10px;       
        width: 50px;
        font-size: 19px;
        transform: translate(16px, 11px);
        color: rgb(0, 0, 0);
    }


    &__secondary-button {
       // border-radius: 4px;
       // padding: 2px 8px;
       // font-size: medium;
       appearance: button;
       backface-visibility: hidden;
       background-color: #06d6a0;
       border-radius: 6px;
       border-width: 0;
       box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
       box-sizing: border-box;
       color: #fff;
       cursor: pointer;
       font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
       font-size: 100%;
       height: 44px;
       line-height: 1.15;
       margin: 12px 0 0;
       outline: none;
       overflow: hidden;
       padding: 0 25px;
       position: relative;
       text-align: center;
       text-transform: none;
       transform: translateZ(0);
       transition: all .2s,box-shadow .08s ease-in;
       user-select: none;
       -webkit-user-select: none;
       touch-action: manipulation;
       //width: 30%;
     
    }

    &__letterbutton {
        &_closed {
            color:lightgray;
            background-color: white;
            border: 1px solid lightgray;
        }
        font-family: "AvenirNextCyr";
        border: 2px solid black;
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 40px;
    }

    &__subtitle {
        padding-top: 10px;
        text-align: left;
    }

    &__answers {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    &__clear-button {
        color: fff;
        border: 1px solid gray;
        background-color: #ef476f;
        border-radius: 2px;
    }

    &__select_letter {
        color: fff;
        font-family: "AvenirNextCyr";
        margin: 20px 20px 0px 20px;
        //border: 1px solid gray;
        //background-color: #ED462B;
       // border-radius: 2px;
    }

    &__full_word {
        color: fff;
        margin: 5px 5px 0px 5px;
        font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
        font-size: 100%;
        height: 60px;
        //border: 1px solid gray;
        //background-color: #ED462B;
       // border-radius: 2px;
    }



    &__fail {
        appearance: button;
        backface-visibility: hidden;
        background-color: rgba(50, 50, 93, .1);
        border-radius: 6px;
        border-width: 0;
        box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
        box-sizing: border-box;
        color: #000;
        cursor: pointer;
        font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
        font-size: 100%;
        height: 60px;
        line-height: 1.15;
        margin: 26px 0 0 0;
        outline: none;
        overflow: hidden;
        padding: 10px 10px;
        position: relative;
        text-align: center;
        text-transform: none;
        transform: translateZ(0);
        transition: all .2s,box-shadow .08s ease-in;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
     }

}